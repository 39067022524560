/**
 * Background Color
 * @description : Selectable background color from cms
 */

(function () {

    var bgEls = document.querySelectorAll(".js-bg-color");
    bgEls.forEach(bgEl => {
        if (bgEl.dataset && bgEl.dataset.bgColor) {
            let color = bgEl.dataset.bgColor.toLowerCase();
            let isGradient = color.includes(" to ");
            let direction = bgEl.dataset.bgDirection == "True" ? "hrz" : "vrt";
            bgEl.dataset.bgReversed == "True" ? color = color.split(" ").reverse().join("-") : color = color.replaceAll(" ", "-");

            if (isGradient) {
                bgEl.classList.add("u-bg-" + direction + "-" + color)
            }
            else if (color != "") {
                bgEl.classList.add("u-bg-" + color)
            }
            else {
                bgEl.classList.add("u-bg-pink")
            }
        }
    })
})();
